import * as React from "react"

import "./grid.scss"

const Grid = (props) => {
    return (
        <>
            <div className="grid-wrap">
                <div className="grid">

                    {props.children}
                    
                </div>
            </div>
        </>
    );
};

export default Grid
