/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Page from "../page/page"

import Header from "../header/header"
import Content from "../content/content"
import Footer from "../footer/footer"

import "./layout.scss"

const Layout = ({children, theme}) => {

	const data = useStaticQuery( graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`)

	return (
		<Page>
			<Header theme={theme} />

			<Content>
				{children}
			</Content>

			<Footer />
		</Page>
	)
}

// Layout.propTypes = {
// 	children: PropTypes.node.isRequired,
// }

// Layout.defaultProps = {
// 	theme: "default",
// }

export default Layout
