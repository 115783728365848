import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import "./content.scss"

const Content = ( props ) => (
	<main className="content-wrap">
        <div className="content">

            {props.children}

        </div>
    </main>
)

// Content.propTypes = {
// 	siteTitle: PropTypes.string,
// }

// Content.defaultProps = {
// 	siteTitle: ``,
// }

export default Content
