import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import "./header.scss"

const navigation = [
	{
		label: "Сім'я",
		url: "/family"
	},
	{
		label: "Умови",
		url: "/conditions"
	},
	{
		label: "Групи і Студії",
		url: "/groups"
	},
	{
		label: "Команда",
		url: "/team"
	},
	{
		label: "Батькам",
		url: "/parents"
	}
];

const Header = ({ theme }) => {
	return (
		<header className={"header-wrap " + theme}>
			<div className="header">

				<div className="logo-wrap">
					<div className="logo">
						<Link to="/">
							<span className="turquoise">L</span><span className="pink">&B</span>
						</Link>
					</div>
				</div>

				<div className="navigation-wrap">
					<ul className="navigation">

						{navigation.map((item, i) => {
							return (
								<li key={i} className="navigation-item">
									<Link to={item.url} activeClassName="active">
										<span>{item.label}</span>
									</Link>
								</li>
							)
						})}

					</ul>
				</div>

				<div className="contacts-wrap">
					<div className="contacts">
						<a href="tel:050 280 33 91" className="pink">050 280 33 91</a>
					</div>
				</div>

			</div>
		</header>
	);
};

// Header.propTypes = {
// 	theme: PropTypes.string,
// }

Header.defaultProps = {
	theme: "default",
}

export default Header
