import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import "./footer.scss"

const Footer = ( props ) => (
	<footer className="footer-wrap">
		<div className="footer">

			<div className="footer-main-wrap">
				<p align="center">
					Во всіх питаннях, ви можете звертатись за номером: 050 280 33 91
				</p>
			</div>

			<div className="footer-content">
				<div className="footer-contacts">
					<p>
						Садочок Lady&amp;Baby<br/>
						с. Софіївська Борщагівка, вул. Волошкова, 81<br/>
					</p>
					<p>
						Сімейний центр<br/>
						с. Софіївська Борщагівка, вул. Райдужна, 96.<br/>
						«Львівський Маєток»<br/>
						тел.: 096 907 907 4
					</p>
					<p>
						Садочок Lady&amp;Baby Pro<br/>
						с. Софіївська Борщагівка, вул. Травнева, 51/54<br/>
						Понеділок-П’ятниця з 7:30 до 19:30
					</p>
				</div>
				<div className="footer-social-media">
				</div>
			</div>

			<div className="footer-copyright">
				<span>&copy; Lady&amp;baby 2022</span>
			</div>
		</div>
	</footer>
)

Footer.propTypes = {
	siteTitle: PropTypes.string,
}

Footer.defaultProps = {
	siteTitle: ``,
}

export default Footer
