import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import "./page.scss"

const Page = ( props ) => (
    <div className="page-wrap">
        <div className="page">

            {props.children}
            
        </div>
    </div>
)

// Content.propTypes = {
// 	siteTitle: PropTypes.string,
// }

// Content.defaultProps = {
// 	siteTitle: ``,
// }

export default Page
