import * as React from "react"

import "./gridItem.scss"

const GridItem = (props) => {
    return (
        <>
            <div className="grid-item-wrap">
                <div className="grid-item">

                    {props.children}
                    
                </div>
            </div>
        </>
    );
};

export default GridItem
