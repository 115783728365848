import * as React from "react"
import { graphql } from 'gatsby'

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"

import Grid from "../components/grid/grid"
import GridItem from "../components/grid/gridItem"

import administratorka from  "../images/administratorka.jpg"

const importAll = (r) => {
	return r.keys().map(r);
}

const images = importAll(require.context('../images', false, /\.(png|jpg|jpe?g|svg)$/));

const team = [
    {
        "title": "Адміністраторка",
        "name": "Юлія Білецька",
        // "image": administratorka
    },
	{
        "title": "Методологиня",
        "name": "Катя Тавлуй",
        // "image": administratorka
    },
	{
        "title": "Очільниця ментального здоров'я",
        "name": "Катя Данилевська",
        // "image": administratorka
    },
	{
        "title": "Медична сестра",
        "name": "Світлана Тримбак",
        // "image": administratorka
    },
	{
        "title": "Ясельна група в садочку Lady&Baby Pro",
        "name": "Аліна Каверіна",
        // "image": administratorka
    },
	{
        "title": "Ясельна група в садочку Lady&Baby Pro",
        "name": "Ангеліна Мамчур",
        // "image": administratorka
    },
	{
        "title": "Середня група в садочку Lady&Baby Pro",
        "name": "Наталя Хутько",
        // "image": administratorka
    },
	{
        "title": "Логопединя",
        "name": "Пані Катя",
        // "image": administratorka
    },
	{
        "title": "Чомусики",
        "name": "Наталя Бондарець",
        // "image": administratorka
    },
	{
        "title": "",
        "name": "Світлана Галичина",
        // "image": administratorka
    },
	{
        "title": "Френдики",
        "name": "Анастасія Чекуркова",
        // "image": administratorka
    },
	{
        "title": "",
        "name": "Анастасія Мельничук",
        // "image": administratorka
    },
	{
        "title": "Розумчики",
        "name": "Галина Недогибченко",
        // "image": administratorka
    },
	{
        "title": "",
        "name": "Альона Марченко",
        // "image": administratorka
    },
	{
        "title": "Пухнастики",
        "name": "Галина Стадніченко",
        // "image": administratorka
    },
	{
        "title": "",
        "name": "Вікторія Амірова",
        // "image": administratorka
    },
	{
        "title": "Няня-господиня",
        "name": "Тетяна Коркіна",
        // "image": administratorka
    },
	{
        "title": "Няня-господиня",
        "name": "Катя Роменська",
        // "image": administratorka
    },
	{
        "title": "Фітнес-тренерка",
        "name": "Ольга Сисоєва",
        // "image": administratorka
    },
	{
        "title": "Тренер з футболу",
        "name": "Пан Юрій",
        // "image": administratorka
    },
	{
        "title": "Педагогиня з акторської майстерності/акторка",
        "name": "Анна Михайличенко",
        // "image": administratorka
    },
	{
        "title": "Викладачка музичних занять за методикою Карла Орфа",
        "name": "Пані Антоніна",
        // "image": administratorka
    },
	{
        "title": "Хореографка",
        "name": "Юлія Сахарова",
        // "image": administratorka
    },
]

const TeamPage = ({data}) => (
	<Layout>
		<div className="heading-wrap">
			<div className="heading">
				<center>
					<h1>Команда</h1>
				</center>
			</div>
		</div>

		<Grid>
			{team.map((item, i) => (
				<GridItem key={i}>

					<div className="card-wrap" style={{
						marging: "0 0 20px"
					}}>
						<div className="card">
							
							<div>
								<img src={item.image} />
							</div>
							<div>
								{item.title}
							</div>
							<div>
								{item.name}
							</div>

						</div>
					</div>

				</GridItem>
			))}
		</Grid>

	</Layout>
)

export const pageQuery = graphql`
	query teamQuery {
		allMarkdownRemark {
			edges {
				node {
					id
					frontmatter {
						path
						image
						title
						name
					},
					excerpt
				}
			}
		}
	}
`

export const Head = () => <Seo title="Команда" />

export default TeamPage
